<script>
import BaseForm from './components/BaseForm.vue'
export default {
  name: 'EditForm',
  components: {
    BaseForm
  },
  data() {
    const {organizationId} = this.$route.query
    return {
      progressing: false,
      initializing: true,
      model: {
        active: true,
        address: null,
        fax: null,
        logo: null,
        name: null,
        parentId: organizationId || null,
        tel: null
      }
    }
  }
}
</script>
