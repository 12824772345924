<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <page-loading :loading="initializing" />
    <div v-if="!initializing">
      <m-card :title="$t('shared.edit')">
        <base-form v-model="model" />

        <template #actions>
          <page-footer edit @submit="handleSubmit(submit)" :loading="progressing" />
        </template>
      </m-card>

      <WorkingSitesTable class="mb-3" :branch-id="id" />
      <UsersTable class="mb-3" :branch-id="id" />

      <template v-if="$hasPermission('dashboard.operation')">
        <ActivityChart class="mb-3" :organization-id="id" />
        <UvChart class="mb-3" :organization-id="id" />
        <UvAlerts class="mb-3" :organization-id="id" />
      </template>
    </div>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
import UsersTable from '@/pages/base/components/UsersTable'
import ActivityChart from '@/pages/base/components/ActivityChart'
import UvChart from '@/pages/base/components/UvChart'
import UvAlerts from '@/pages/base/components/UvAlerts'
import WorkingSitesTable from '@/pages/base/components/WorkingSitesTable'

export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  components: {
    UsersTable,
    ActivityChart,
    UvChart,
    UvAlerts,
    WorkingSitesTable
  },
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      return this.$api.branches.getById(this.id).then((res) => {
        this.model = res
        this.$setBreadcrumbs([
          {text: this.$t('nav.branches'), to: '/branches/index'},
          {text: res.name, disabled: true}
        ])
      })
    },
    submit() {
      this.progressing = true
      return this.$api.branches
        .update(this.id, this.model)
        .then(() => {
          this.$showSuccess(this.$t('branches.branchUpdated'))
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    }
  }
}
</script>
